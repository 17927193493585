import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as actionCreators from '../../actions';
import { sendAccessToken } from '../../queries/mutations';
import { getLangDefinition } from '../../utils/helpers';
import HeaderPlaceholder from '../Placeholders/HeaderPlaceholder';
import AdblockerDetector from '../Banner/BlockerDetector';
import FooterPlaceholder from '../Placeholders/FooterPlaceholder';
import Loader from '../Loader';
import AuthModal from '../AuthModal';
import SideMenuModal from '../SideMenuModal';
import { useBridgeState } from '../../bridge';
import { togglePromotionModalView } from '../../actions';
import PromotionModalView from '../PromotionModalView';

const WhiteHeader = dynamic(() => import('../WhiteHeader'), {
  loading: HeaderPlaceholder,
  ssr: false
});

const TabBarNavigation = dynamic(() => import('../TabBarNavigation'), {
  loading: HeaderPlaceholder,
  ssr: false
});

const Footer = dynamic(() => import('../Footer'), {
  loading: FooterPlaceholder,
  ssr: false
});

const mapStateToProps = state => {
  return {
    isVisible: state.drawerUI.isVisible,
    isSideMenuVisible: state.sideMenuModal?.isVisible,
    isPromotionModalView: state.promotionModalView?.isVisible,
    isAuthVisible: state?.authModal?.isVisible
  };
};

const Layout = ({
  children,
  setBlockerDetector,
  toggleSideMenuModal,
  isAuthVisible,
  isSideMenuVisible,
  isPromotionModalView,
  toggleAuthModal,
  headerTitle,
  isBottomNavigation = true,
  isShowHeader = true,
  isFixedHeader = false
}) => {
  const {
    i18n: { language }
  } = useTranslation();
  const router = useRouter();
  const { isWebView } = useBridgeState();
  const allClasses = classNames(
    'main-content',
    `locale-${getLangDefinition(language)}`
  );

  const closeAuthModalHandler = () => {
    toggleAuthModal(false);

    router.push(`${router.asPath.split('#')[0]}`, undefined, {
      shallow: true
    });
  };
  const accessToken = router?.query.access_token;
  const [mutateToken, { loading }] = useMutation(sendAccessToken);

  useMemo(() => {
    if (accessToken) {
      mutateToken({
        variables: { accessToken }
      });
    }
  }, []);

  useEffect(() => {
    if (router?.asPath.match('#authentication')) {
      toggleAuthModal(true);
    } else if (router?.asPath.match('#more')) {
      toggleSideMenuModal(true);
    } else if (router?.asPath.match('#service_feature')) {
      togglePromotionModalView(true);
    } else if (router?.asPath.match('#service_bump')) {
      togglePromotionModalView(true);
    } else if (router?.asPath.match('#service_vip')) {
      togglePromotionModalView(true);
    }

    router?.events.on('hashChangeComplete', url => {
      if (url.match('#authentication')) {
        toggleAuthModal(true);
      } else {
        toggleAuthModal(false);
      }

      if (url.match('#more')) {
        toggleSideMenuModal(true);
      } else {
        toggleSideMenuModal(false);
      }

      if (
        url.match('#service_bump') ||
        url.match('#service_feature') ||
        url.match('#service_vip')
      ) {
        togglePromotionModalView(true);
      } else {
        togglePromotionModalView(false);
      }
    });
  }, []);

  if (loading) return null;

  return (
    <div className={allClasses}>
      {isShowHeader && <WhiteHeader {...{ headerTitle, isFixedHeader }} />}
      <AdblockerDetector setBlockerDetector={setBlockerDetector} />
      {children}
      <Loader pageLoader={true} />
      {isBottomNavigation && <TabBarNavigation />}
      {!(isWebView || isSideMenuVisible) && (
        <Footer
          isTabBarExist={isBottomNavigation}
          isBackgroundWhite={!isShowHeader}
        />
      )}
      {isSideMenuVisible && <SideMenuModal />}
      {isPromotionModalView && <PromotionModalView />}
      {isAuthVisible && (
        <AuthModal
          isOpen={isAuthVisible}
          modalId="auth"
          closeHandler={closeAuthModalHandler}
        />
      )}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  toggleDrawer: PropTypes.func,
  toggleAuthModal: PropTypes.func,
  isVisible: PropTypes.bool,
  isAuthVisible: PropTypes.bool,
  isSideMenuVisible: PropTypes.bool,
  isPromotionModalView: PropTypes.bool,
  setBlockerDetector: PropTypes.func,
  toggleSideMenuModal: PropTypes.func,
  isBottomNavigation: PropTypes.bool,
  isShowHeader: PropTypes.bool,
  isFixedHeader: PropTypes.bool,
  headerTitle: PropTypes.string
};

export default connect(mapStateToProps, actionCreators)(Layout);
