// eslint-disable-next-line import/prefer-default-export
export const currentItemMockData = {
  phonesMasked: ['(051) 205-33-●●', '(051) 205-33-●●'],
  phones: ['(051) 205-33-22', '(051) 205-33-22'],
  itemTitle: 'Продаётся 4-комн. новостройка 185 м², м. Элмляр Академиясы',
  ownerImageUrl:
    'https://bina.azstatic.com/uploads/f460x345/2020%2F08%2F07%2F18%2F46%2F25%2Fd6fd880e-1458-42bd-b936-d9bbb523c48c%2F26516_g0wdlVC9O3RsrLvo7t9IMw.jpg',
  locationAddress:
    'Bakı ş., Yasamal Yasamal Yasamal Yasr., H. Zərdabi pr. 3166',
  agencyImageUrl:
    'https://bina.azstatic.com/uploads/o450x450/2023%2F07%2F27%2F16%2F30%2F02%2F0cd60fde-3217-4029-9e52-c359fadb6f8e%2Flogo%20%281%29.jpg',
  agencyName: 'Dost Əmlak 20 Yanvar',
  agencyId: 'qafqaz-emlak',
  messageStatus:
    'Это объявление отправлено на проверку. В случае соблюдения всех правил, объявление будет размещено в течение одного рабочего часа.',
  itemDescription:
    '«Dost Əmlak 20 Yanvar», работающая с 2013 года, является одной из крупнейших компаний в сфере недвижимости в стране. Цена указана врено. Основными направлениями деятельности являются управление недвижимостью, инвестиции и управление активами. В настоящее время компания реализует проекты «Nizami Boutique House», «Gumush Residence», «Hillside Residence» и другие.',
  photos: [
    {
      __typename: 'Photo',
      full: 'https://bina.azstatic.com/uploads/full/2023%2F07%2F31%2F16%2F44%2F59%2F866a05c3-d42d-4447-b198-332147a0003c%2F82102_ia-mokKDqGD8bQv73oHJVg.jpg',
      f660x496:
        'https://bina.azstatic.com/uploads/f660x496/2023%2F07%2F31%2F16%2F44%2F59%2F866a05c3-d42d-4447-b198-332147a0003c%2F82102_ia-mokKDqGD8bQv73oHJVg.jpg'
    },
    {
      __typename: 'Photo',
      full: 'https://bina.azstatic.com/uploads/full/2023%2F07%2F31%2F16%2F45%2F01%2F49d2d6d8-9670-4dfe-98d2-af6aa3a83d2c%2F81985_qoZ-9ZX8vZ6Pg_T5RO0fag.jpg',
      f660x496:
        'https://bina.azstatic.com/uploads/f660x496/2023%2F07%2F31%2F16%2F45%2F01%2F49d2d6d8-9670-4dfe-98d2-af6aa3a83d2c%2F81985_qoZ-9ZX8vZ6Pg_T5RO0fag.jpg'
    },
    {
      __typename: 'Photo',
      full: 'https://bina.azstatic.com/uploads/full/2023%2F07%2F31%2F16%2F45%2F01%2Fda9bc130-4773-4c58-8c7f-570dc5a8c3dd%2F82021_ckOXEq19bVuqmGPuUalv0g.jpg',
      f660x496:
        'https://bina.azstatic.com/uploads/f660x496/2023%2F07%2F31%2F16%2F45%2F01%2Fda9bc130-4773-4c58-8c7f-570dc5a8c3dd%2F82021_ckOXEq19bVuqmGPuUalv0g.jpg'
    },
    {
      __typename: 'Photo',
      full: 'https://bina.azstatic.com/uploads/full/2023%2F07%2F31%2F16%2F45%2F01%2Fd96c4f26-f16b-455c-8729-cda61b216b94%2F82099_csGUGDZXDILTFZXWORg-Yg.jpg',
      f660x496:
        'https://bina.azstatic.com/uploads/f660x496/2023%2F07%2F31%2F16%2F45%2F01%2Fd96c4f26-f16b-455c-8729-cda61b216b94%2F82099_csGUGDZXDILTFZXWORg-Yg.jpg'
    },
    {
      __typename: 'Photo',
      full: 'https://bina.azstatic.com/uploads/full/2023%2F07%2F31%2F16%2F45%2F02%2F29c38f01-96fa-4be2-9938-efbcd6405407%2F82087_-JAB3CU6vCObslcJP0V2GA.jpg',
      f660x496:
        'https://bina.azstatic.com/uploads/f660x496/2023%2F07%2F31%2F16%2F45%2F02%2F29c38f01-96fa-4be2-9938-efbcd6405407%2F82087_-JAB3CU6vCObslcJP0V2GA.jpg'
    }
  ],
  payedAd: 'Оплачено до 19 сентября 2023, 21:56'
};
