import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Link from 'next/link';
import { useRouter } from 'next/router';
import RadioButton from '../../RadioButton';
import StickyElement from '../../StickyElement';
import Button from '../../Button';
import { currentItemMockData } from '../../../utils/helpers/currentItemMockData';
import { toggleRestoreAlertModal } from '../../../actions';
import WhiteHeader from '../../WhiteHeader';
import PromotionInfo from './PromotionInfo';
import PersonalAdsLimitPayment from './PersonalAdsLimitPayment';
import PromotionHeader from './PromotionHeader';
import useToggle from '../../../hooks/useToggle';

const Promotion = ({
  onCloseHandler,
  content,
  paymentList,
  isPremiumView,
  isPayed,
  isPaymentMethodView,
  isRestoreView,
  isPersonalAdsLimitView
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { id } = router.query;
  const { t } = useTranslation();
  const [termValue, setTermValue] = useState(content?.serviceTermArray[1]);
  const [paymentService, setPaymentService] = useState(paymentList[0]);
  const [showAlert, toggleAlert] = useToggle(false);
  const agreement = t('authentication.agreement');
  const rules = t('authentication.rules');
  const phone = '(012) 505-08-04';
  const freeAdsBalance = '4';

  const onChangeTermsHandler = e => {
    const selectedValue = content?.serviceTermArray.find(
      item => item.id === e.target.value
    );
    setTermValue(selectedValue);
  };

  const onChangeServiceHandler = e => {
    const selectedValue = paymentList.find(item => item.id === e.target.value);
    setPaymentService(selectedValue);
  };

  const handleSubmit = () => {
    if (isRestoreView) {
      onCloseHandler();
      dispatch(toggleRestoreAlertModal(true));
      return;
    }
    console.log('form ==>', termValue, paymentService);
  };

  const onLimitPaymentHandleClick = () => {
    onCloseHandler();
  };

  useEffect(() => {
    if (isPremiumView) {
      setTermValue(content?.serviceTermArray[2]);
    } else {
      setTermValue(content?.serviceTermArray[1]);
    }
  }, [isPremiumView, content]);

  if (isPersonalAdsLimitView) {
    return (
      <PersonalAdsLimitPayment
        message={content?.message}
        additionalMessage={content?.additionalMessage}
        handleSubmit={onLimitPaymentHandleClick}
        {...{ onCloseHandler, id, freeAdsBalance, showAlert, toggleAlert }}
      />
    );
  }

  return (
    <div className="promotion">
      {isPaymentMethodView ? (
        <WhiteHeader />
      ) : (
        <PromotionHeader
          icon={content?.icon}
          onCloseHandler={onCloseHandler}
          title={content?.title}
        />
      )}
      {isPaymentMethodView ? (
        <div className="promotion__payment-method">
          <div className="promotion__payment-method-note">
            <Trans
              i18nKey="pages.current_item.promotion.note"
              values={{
                id
              }}
            >
              <Link href={`/items/${id}`}>{id}</Link>
            </Trans>
          </div>
          <PromotionInfo
            message={content?.message}
            additionalClasses={'promotion__info--payment'}
          />
          <PromotionInfo
            message={content?.additionalMessage}
            additionalClasses={'promotion__info--payment'}
          />
        </div>
      ) : (
        <PromotionInfo message={content?.message} />
      )}
      {isPayed && (
        <PromotionInfo
          message={currentItemMockData.payedAd}
          additionalClasses={'promotion__info--payed'}
        />
      )}
      <div className="promotion__terms">
        <span className="promotion__terms-title">
          {t('pages.current_item.promotion.term_of_service')}
        </span>
        {content?.serviceTermArray.map((item, i) => {
          return (
            <div key={i} className="promotion__terms-radio-btns">
              <RadioButton
                key={`term_${i}`}
                data={item}
                onChangeHandler={onChangeTermsHandler}
                currentValue={termValue?.id || ''}
                additionalClasses="promotion__terms--radio-reverse"
                name="terms"
                cypress="terms"
              />
            </div>
          );
        })}
      </div>
      <div className="promotion__terms">
        <span className="promotion__terms-title">
          {t('pages.current_item.promotion.payment_method')}
        </span>
        {paymentList.map((item, i) => {
          if (!item?.isShow) {
            return null;
          }
          return (
            <div key={i} className="promotion__terms-radio-btns">
              <RadioButton
                key={`serv_${i}`}
                data={item}
                onChangeHandler={onChangeServiceHandler}
                currentValue={paymentService.id || ''}
                additionalClasses="promotion__terms--radio-reverse"
                name="service"
                cypress="service"
              />
            </div>
          );
        })}
      </div>
      <div className="promotion__confirmation">
        <Trans
          i18nKey="pages.current_item.promotion.payment_info"
          values={{
            agreement,
            rules
          }}
        >
          <a href="/pages/terms-and-conditions">{{ agreement }}</a>
          <a href="/pages/rules">{{ rules }}</a>
        </Trans>
      </div>
      {isPaymentMethodView && (
        <div className="promotion__more-ads">
          <Trans
            i18nKey="pages.current_item.promotion.more_ads"
            values={{
              phone
            }}
          >
            <a href={`tel:${phone}`}>{phone}</a>
          </Trans>
        </div>
      )}
      <StickyElement bottomPos={true} bottomOffset={10}>
        {(isSticky, wrapperRef) => {
          return (
            <div className={'bottom-phone-container'} ref={wrapperRef}>
              <div
                className={cn(
                  'bottom-phone__wrapper',
                  'bottom-phone__wrapper--sticky'
                )}
              >
                <Button
                  text={t('pages.current_item.buttons.pay')}
                  additionalClass="bottom-phone"
                  onClickHandler={handleSubmit}
                />
              </div>
            </div>
          );
        }}
      </StickyElement>
    </div>
  );
};

Promotion.propTypes = {
  onCloseHandler: PropTypes.func,
  content: PropTypes.object,
  paymentList: PropTypes.array,
  isPremiumView: PropTypes.bool,
  isPayed: PropTypes.bool,
  isRestoreView: PropTypes.bool,
  isPaymentMethodView: PropTypes.bool,
  isPersonalAdsLimitView: PropTypes.bool
};
export default Promotion;
