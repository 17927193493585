import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BinaModal from '../ReactModal';
import { selectPromotionModalViewState } from '../../store/selectors';
import Promotion from '../Ad/Promotion';
import PromotionIcon from '../../public/static/images/item/promotion.svg';
import VipIcon from '../../public/static/images/item/vip.svg';
import PremiumIcon from '../../public/static/images/item/feature.svg';
import useTogglePromotionModalView from '../../hooks/useTogglePromotionModalView';

const PromotionModalView = () => {
  const { t } = useTranslation();
  const {
    onCloseHandler,
    splitPath,
    isRestoreView,
    isPremiumView,
    isPaymentMethodView,
    isPersonalAdsLimitView
  } = useTogglePromotionModalView();
  const { isVisible: isVisiblePromotionModalView } = useSelector(
    selectPromotionModalViewState
  );
  const isAuth = true;
  const isPayed = false;

  const promotionViewContent = {
    service_bump: {
      icon: PromotionIcon,
      title: t('pages.current_item.promotion.raise_ad'),
      message: t('pages.current_item.promotion.raise_ad_info'),
      serviceTermArray: [
        { id: '1', name: '1 раз / 3,00 AZN' },
        { id: '2', name: '3 раза (каждые 24 часа) / 6,00 AZN' },
        { id: '3', name: '5 раз (каждые 24 часа) / 9,00 AZN' },
        { id: '4', name: '10 раз (каждые 24 часа) / 15,00 AZN' }
      ]
    },
    service_vip: {
      icon: VipIcon,
      title: t('pages.current_item.promotion.make_vip'),
      message: t('pages.current_item.promotion.make_vip_info'),
      serviceTermArray: [
        { id: '1', name: '5 дней / 10,00 AZN' },
        { id: '2', name: '15 дней / 20,00 AZN' },
        { id: '3', name: '30 дней / 30,00 AZN' }
      ]
    },
    service_feature: {
      icon: PremiumIcon,
      title: t('pages.current_item.promotion.make_premium'),
      message: t('pages.current_item.promotion.make_premium_info'),
      serviceTermArray: [
        { id: '1', name: '1 день / 5,00 AZN' },
        { id: '2', name: '5 дней / 20,00 AZN' },
        { id: '3', name: '15 дней / 40,00 AZN' },
        { id: '4', name: '30 дней / 30,00 AZN' }
      ]
    },
    restore: {
      icon: null,
      title: t('pages.current_item.promotion.make_restore'),
      message: t('pages.current_item.promotion.make_restore_info'),
      serviceTermArray: [
        { id: '1', name: '1 объявление / 5,00 AZN' },
        { id: '2', name: '5 объявлений / 20,00 AZN' },
        { id: '3', name: '20 объявлений / 60,00 AZN' }
      ]
    },
    limit_payment: {
      icon: null,
      title: t('pages.current_item.promotion.make_restore'),
      message: t('pages.current_item.promotion.free_limit_per_month'),
      additionalMessage: t('pages.current_item.promotion.send_for_review'),
      serviceTermArray: [
        { id: '1', name: '1 объявление / 5,00 AZN' },
        { id: '2', name: '5 объявлений / 20,00 AZN' },
        { id: '3', name: '20 объявлений / 60,00 AZN' }
      ]
    },
    personal_ads: {
      icon: null,
      title: t('pages.current_item.promotion.make_restore'),
      message: t('pages.current_item.promotion.free_limit_per_month'),
      additionalMessage: t('pages.current_item.promotion.send_for_review'),
      serviceTermArray: [
        { id: '1', name: '1 объявление / 5,00 AZN' },
        { id: '2', name: '5 объявлений / 20,00 AZN' },
        { id: '3', name: '20 объявлений / 60,00 AZN' }
      ]
    }
  };

  const paymentList = [
    {
      id: '10',
      name: t('pages.current_item.promotion.credit_card'),
      isShow: true
    },
    { id: '11', name: 'Portmanat', isShow: true },
    {
      id: '12',
      name: t('pages.current_item.promotion.personal_account', {
        amount: '22,00'
      }),
      isShow: isAuth
    }
  ];

  const content = promotionViewContent[splitPath[1]];

  return (
    <BinaModal
      modalId={'promotion-view'}
      isOpen={isVisiblePromotionModalView}
      overlayClassName="modal__overlay--side-menu"
      contentClassName="modal__content--side-menu"
    >
      <div
        className="modal__content--side-menu-container"
        data-cy="promotion-view-container"
      >
        <Promotion
          {...{
            onCloseHandler,
            content,
            paymentList,
            isPremiumView,
            isPayed,
            isRestoreView,
            isPaymentMethodView,
            isPersonalAdsLimitView
          }}
        />
      </div>
    </BinaModal>
  );
};

export default PromotionModalView;
